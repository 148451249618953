import { Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authAtom } from './state';
import { useUserActions } from './actions';

import { Nav } from './components';
import { history } from './helpers';
import { Home, Login, Employees } from './views';

import 'bulma/css/bulma.min.css';

// import logo from './logo.svg';
// import './App.css';

import React, { Component } from "react";

function App() {
    return (
        <section className="hero">
            <div className="hero-header">
                <Nav />
            </div>
            <div className="hero-body">
                <Routes>
                    <Route index element={<RequireAuth><Home /></RequireAuth>} />
                    <Route path="/" element={<RequireAuth><Home /></RequireAuth>} />
                    <Route path="/employees" element={<RequireAuth><Employees /></RequireAuth>} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />}/>
                    {/*<Route path="*" element={<Navigate to="/" replace />}/>*/}
                </Routes>
            </div>
        </section>
    );
}

// const AuthProvider = ({ children }) => {
//     const navigate = useNavigate();
//
//     const [token, setToken] = React.useState(null);
//
//     const handleLogin = async () => {
//         const token = await fakeAuth();
//
//         setToken(token);
//         navigate('/dashboard');
//     };
//
//     const handleLogout = () => {
//         setToken(null);
//     };
//
//     const value = {
//         token,
//         onLogin: handleLogin,
//         onLogout: handleLogout,
//     };
//
//     return (
//         <AuthContext.Provider value={value}>
//             {children}
//         </AuthContext.Provider>
//     );
// };

function RedirectAuthorized({ children }: { children: JSX.Element }) {
    const auth = useRecoilValue(authAtom);
    let location = useLocation();

    if (auth !== null && auth.token) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
}

function RequireAuth({ children }: { children: JSX.Element }) {
    const auth = useRecoilValue(authAtom);
    let location = useLocation();

    if (auth == null || !auth.user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

function Logout() {
    let location = useLocation();
    const userActions = useUserActions();
    userActions.logout();

    return <Navigate to="/login" state={{ from: location }} replace />;
}

export default App;
