import { useSetRecoilState } from 'recoil';
import { useFetchWrapper } from '../helpers';
import {employeeAtom} from '../state';

export { useEmployeesActions };

function useEmployeesActions () {
    const baseUrl = process.env.NODE_ENV === 'production' ? `https://${document.location.host}/api` : `${process.env.REACT_APP_API_URL}/api`;
    const fetchWrapper = useFetchWrapper();
    const setEmployees = useSetRecoilState(employeeAtom);

    return {
        patch,
        load,
        all
    }

    function patch(employee) {
        return fetchWrapper.patch(`${baseUrl}/employee`, { employee });
    }

    function load() {
        return fetchWrapper.get(`${baseUrl}/sync-employee`);
    }

    function all() {
        return fetchWrapper.get(`${baseUrl}/employees`);
    }
}