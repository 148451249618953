import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { authAtom } from '../state';
import { useUserActions } from '../actions';
import { useState } from "react";

function Nav() {
    const auth = useRecoilValue(authAtom);
    const userActions = useUserActions();
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
    }

    const burgerClassnames = classNames({
        'navbar-burger': true,
        'is-active': isActive
    });

    const menuClassnames = classNames({
        'navbar-menu': true,
        'is-active': isActive
    });

    // only show nav when logged in
    if (!auth) return null;

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a role="button"
                   className={burgerClassnames}
                   aria-label="menu"
                   aria-expanded="false"
                   data-target="navbar-main"
                   onClick={() => handleClick()}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbar-main" className={menuClassnames}>
                <div className="navbar-start">
                    <NavLink to="/" className="navbar-item">Home</NavLink>
                    <NavLink to="/employees" className="navbar-item">Employees</NavLink>
                    <a onClick={userActions.logout} className="navbar-item">Logout</a>
                </div>
            </div>
        </nav>
    );
}

export { Nav };