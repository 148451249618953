import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { authAtom } from '../state';
import { useUserActions } from '../actions';

export { Login };

function Login({ history }) {
    const auth = useRecoilValue(authAtom);
    const userActions = useUserActions();
    const navigate = useNavigate();

    useEffect(() => {
        // redirect to home if already logged in
        if (auth) {
            navigate('/');
        }
    }, [auth]);

    // form validation rules
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setError, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ username, password }) {
        return userActions.login(username, password)
            .catch(error => {
                if (error.message) {
                    setError('apiError', { message: error.message });
                } else {
                    setError('apiError', { message: error });
                }
            });
    }

    return (
        <div className='container'>
            <form className="box" onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                    <label className="label">Username</label>
                    <div className="control">
                        <input
                            className={`input ${errors.username ? 'is-danger' : ''}`}
                            type="email"
                            autoComplete="username"
                            {...register('username')} />
                    </div>
                    <div className='info'>
                        <div className="invalid-feedback">{errors.username?.message}</div>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Password</label>
                    <div className="control">
                        <input
                            className={`input ${errors.password ? 'is-danger' : ''}`}
                            type="password"
                            autoComplete="password"
                            {...register('password')} />
                    </div>
                    <div className='info'>
                        <div className="invalid-feedback">{errors.password?.message}</div>
                    </div>
                </div>

                <button disabled={isSubmitting} className="button is-primary">
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Sign in
                </button>

                {errors.apiError &&
                    <div className="is-danger mt-3 mb-0">{errors.apiError?.message}</div>
                }
            </form>
        </div>
    )
}
