import { useSetRecoilState } from 'recoil';
import { useFetchWrapper } from '../helpers';
import { recordsAtom } from '../state';

export { useRecordsActions };

function useRecordsActions () {
    const baseUrl = process.env.NODE_ENV === 'production' ? `https://${document.location.host}/api` : `${process.env.REACT_APP_API_URL}/api`;
    const fetchWrapper = useFetchWrapper();
    const setRecords = useSetRecoilState(recordsAtom);

    return {
        sync,
    }

    async function sync(data) {
        return fetchWrapper.post(`${baseUrl}/sync`, data);
    }
}