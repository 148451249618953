import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';

import {authAtom, recordsAtom, selectedRecordsAtom, usersAtom} from '../state';
import { useUserActions, useRecordsActions, useEmployeesActions } from '../actions';

export { Home };

function Home() {
    const auth = useRecoilValue(authAtom);
    const records = useRecoilValue(recordsAtom);
    const selectedRecords = useRecoilValue(selectedRecordsAtom);
    const setRecords= useSetRecoilState(recordsAtom);
    const setSelectedRecords= useSetRecoilState(selectedRecordsAtom);

    const userActions = useUserActions();
    const recordsActions = useRecordsActions();
    const employeeActions = useEmployeesActions();

    const [uploadForm, hideUploadForm] = useState(false);
    const [isSyncing, toggleIsSyncing] = useState(false);
    const [notFound, setNotFound] = useState([]);
    const [processed, setProcessed] = useState(0);
    const [overwrite, setOverwrite] = useState(false);
    const [clearOption, setClearOption] = useState(0);

    // form validation rules
    const validationSchema = Yup.object().shape({
        file: Yup.mixed().required('File is required'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setError, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const handleSyncHolded = async () => {
        let recordsData = [];
        let recordsSource = null;

        if (selectedRecords.length) {
            recordsData = selectedRecords.map((item, i) => {
                return {
                    id: item['anvizId'],
                    start: item['beginTime'],
                    end: item['endTime'],
                }
            });

            toggleIsSyncing(true);
            recordsActions.sync( { records: recordsData, overwrite, clearOption: parseInt(clearOption) })
                .then(response => {
                    toggleIsSyncing(false);
                    if (response.data.notFound) setNotFound(response.data.notFound);
                    if (response.data.processed) setProcessed(response.data.processed);
                })
                .catch(error => {
                    setError('apiError', { message: error });
                });
        } else {
            setError('apiError', { message: 'nothing selected' });
        }
    }

    const handleReset = () => {
        setProcessed(0);
        setNotFound([]);
        setRecords(null);
        setOverwrite([]);
        hideUploadForm(false);
    }

    const handleOverwrite = (event) => {
        setOverwrite(event);
    }

    const handleClearOption = (event) => {
        setClearOption(event.target.value);
    }

    const onSubmit = async (data) => {
        if (data.file.length !== 1) {
            setError('file', { message: "select one file" });
        } else {
            return userActions.upload(data)
                .then(response => {
                    if (response.status === 'success') {
                        if (response.data.json.length) {
                            /* check file format */
                            if (response.data.json[0]['User ID'] && response.data.json[0]['Name'] &&
                                response.data.json[0]['Department'] && response.data.json[0]['Begin Time'] &&
                                response.data.json[0]['End Time'] && response.data.json[0]['Total Worktime']) {

                                hideUploadForm(true);

                                const data = response.data.json.map((v, idx) => {
                                    return {
                                        id: idx,
                                        anvizId: v['User ID'],
                                        name: v['Name'],
                                        department: v['Department'],
                                        beginTime: v['Begin Time'],
                                        endTime: v['End Time'],
                                        totalWorktime: v['Total Worktime'],
                                    }
                                });
                                setRecords(data);
                            } else {
                                setError('file', { message: "incorrect file format" });
                            }

                        }
                    } else {
                        setError('file', { message: "incorrect file type" });
                    }

                })
                .catch(error => {
                    setError('apiError', { message: error });
                });
        }
    }

    const onRowsSelectionHandler = (ids) => {
        const selectedData = ids.map((id) => records.find((row) => row.id === id));
        setSelectedRecords(selectedData);
    };

    const columns = [
        { field: 'anvizId', headerName: 'Anviz ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 300 },
        { field: 'department', headerName: 'Department', width: 200 },
        { field: 'beginTime', headerName: 'Start', width: 200 },
        { field: 'endTime', headerName: 'Stop', width: 200 },
        { field: 'totalWorktime', headerName: 'Worktime' }
    ];

    const uploadFormClassnames = classNames({
        'box': true,
        'is-hidden': uploadForm || records
    });

    const syncButtonClassnames = classNames({
        'button is-primary': true,
        'is-loading': isSyncing
    });

    const resetButtonClassnames = classNames({
        'button is-warning': true,
        'is-hidden': !records
    });

    const showResultClassnames = classNames({
        'mt-5': true,
        'is-hidden': !processed && !notFound.length
    });

    const showSuccessResultClassnames = classNames({
        'has-text-success': true,
        'is-hidden': !processed
    });

    const showErrorsResultClassnames = classNames({
        'has-text-danger': true,
        'is-hidden': !notFound.length
    });

    return (
        <div>
            <div className='container mt-5'>
                <h1>Hi {auth?.user.login}!</h1>
                <p>You're logged in!</p>
            </div>

            <div className='container mt-5'>
                <form className={uploadFormClassnames} onSubmit={handleSubmit(onSubmit)}>
                    <div className="field">
                        <label className="label">Anviz timesheet</label>
                        <div className="control">
                            <input type="file" {...register("file")} />
                        </div>
                        <div className='info'>
                            <div className="invalid-feedback">{errors.file?.message}</div>
                        </div>
                    </div>

                    <button disabled={isSubmitting} className="button is-primary">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Upload file
                    </button>

                    {errors.apiError &&
                        <div className="is-danger mt-3 mb-0">{errors.apiError?.message}</div>
                    }
                </form>
                {
                    records?
                        <div className='box'>
                            <div className="field">
                                <label className="label">Loaded {records.length} records</label>
                                <div className="control">
                                    <div className='buttons is-grouped'>
                                        <button disabled={isSyncing} className={syncButtonClassnames} onClick={() => handleSyncHolded()}>
                                            Update Holded
                                        </button>

                                        <button disabled={isSyncing} className={resetButtonClassnames} onClick={() => handleReset()}>
                                            Select New File
                                        </button>
                                    </div>

                                    <p className={showResultClassnames}>
                                        <p className={showErrorsResultClassnames}><b>Not Found Anviz IDs: </b>
                                            {
                                                notFound.map((value, i) => {
                                                    return (
                                                        <span key={i}>{value}
                                                            {
                                                                i < notFound.length - 1? ', ' : null
                                                            }
                                                        </span>
                                                    )
                                                })
                                            }
                                        </p>
                                        <p className={showSuccessResultClassnames}><b>Processed records: </b> {processed}</p>
                                    </p>
                                </div>
                            </div>
                            <div className='field'>
                                <div className="control">
                                    <label className="checkbox">
                                        <input type="checkbox" checked={overwrite} onChange={() => { handleOverwrite(!overwrite)} }/>
                                        Overwrite existing entries in Holded
                                    </label>
                                </div>
                            </div>
                            {
                                overwrite?
                                    <div className='field'>
                                        <div className="control">
                                            <div className="select">
                                                <select value={clearOption} onChange={(evt) => { handleClearOption(evt) } }>
                                                    <option defaultValue>Select option</option>
                                                    <option value={1}>Clear current month</option>
                                                    <option value={2}>Clear only overlapping data</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>:null
                }
                <div className='container table'>
                    {
                        records ?
                            <DataGrid
                                rows={records}
                                columns={columns}
                                pageSize={50}
                                rowsPerPageOptions={[50]}
                                checkboxSelection
                                disableSelectionOnClick
                                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                            /> : null
                    }
                </div>

                {/*{*/}
                {/*    records?*/}
                {/*        <table className='table is-striped is-fullwidth'>*/}
                {/*            <thead>*/}
                {/*            <tr>*/}
                {/*                <th>ID</th>*/}
                {/*                <th>Name</th>*/}
                {/*                <th>Department</th>*/}
                {/*                <th>Start</th>*/}
                {/*                <th>Stop</th>*/}
                {/*                <th>Worktime</th>*/}
                {/*            </tr>*/}
                {/*            </thead>*/}
                {/*            <tbody>*/}
                {/*            {*/}
                {/*                records.map((item, i) => {*/}
                {/*                    const id = item['User ID'];*/}
                {/*                    const name = item['Name'];*/}
                {/*                    const department = item['Department'];*/}
                {/*                    const start = item['Begin Time'];*/}
                {/*                    const stop = item['End Time'];*/}
                {/*                    const worktime = item['Total Worktime'];*/}

                {/*                    return (*/}
                {/*                        <tr key={i}>*/}
                {/*                            <th>{id}</th>*/}
                {/*                            <td>{name}</td>*/}
                {/*                            <td>{department}</td>*/}
                {/*                            <td>{start}</td>*/}
                {/*                            <td>{stop}</td>*/}
                {/*                            <td>{worktime}</td>*/}
                {/*                        </tr>*/}
                {/*                    )*/}
                {/*                })*/}
                {/*            }*/}

                {/*            </tbody>*/}
                {/*        </table>:null*/}
                {/*}*/}

            </div>
        </div>
    );
}
