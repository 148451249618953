import { useSetRecoilState } from 'recoil';
import { history, useFetchWrapper } from '../helpers';
import { authAtom, usersAtom } from '../state';

export { useUserActions };

function useUserActions () {
    const baseUrl = process.env.NODE_ENV === 'production' ? `https://${document.location.host}/api` : `${process.env.REACT_APP_API_URL}/api`;
    const fetchWrapper = useFetchWrapper();
    const setAuth = useSetRecoilState(authAtom);
    const setUsers = useSetRecoilState(usersAtom);


    return {
        login,
        logout,
        upload
    }

    function login(username, password) {
        return fetchWrapper.post(`${baseUrl}/login`, { username, password })
            .then(user => {
                localStorage.setItem('user', JSON.stringify(user));
                setAuth(user);
            });
    }

    function logout() {
        // remove user from local storage, set auth state to null and redirect to login page
        localStorage.removeItem('user');
        setAuth(null);
        history.push('/login');
    }

    async function upload(data) {
        const formData = new FormData();
        formData.append("file", data.file[0]);

        return fetchWrapper.post(`${baseUrl}/upload`, formData);
    }
}
