import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {authAtom, employeeAtom, recordsAtom, usersAtom} from '../state';
import { useUserActions, useRecordsActions, useEmployeesActions } from '../actions';

export { Employees };

function Employees() {
    const auth = useRecoilValue(authAtom);
    const employees = useRecoilValue(employeeAtom);
    const setEmployees = useSetRecoilState(employeeAtom);

    const employeeActions = useEmployeesActions();
    const [firstLoad, setFirstLoad] = useState(false);
    const [isLoading, toggleIsLoading] = useState(false);

    const [inEditMode, setInEditMode] = useState({
        status: false,
        touched: false,
        rowKey: null
    });

    const [anvizID, setAnvizID] = useState(null);

    useEffect(() => {
        employeeActions.all()
            .then(response => {
                toggleIsLoading(false);
                if (response.json.length) {
                    setEmployees(response.json)
                }
            })
            .catch(error => {
                // setError('apiError', { message: error });
            });
    }, [firstLoad]);

    const handleLoadEmployeesHolded = async () => {
        toggleIsLoading(true);
        employeeActions.load()
            .then(response => {
                toggleIsLoading(false);
                if (response.json.length) {
                    setEmployees(response.json)
                }
            })
            .catch(error => {
                // setError('apiError', { message: error });
            });
    }


    const onEdit = ({id, anviz_id}) => {
        console.log(`onEdit ID: ${id} value: ${anviz_id}`);

        if (inEditMode.status && inEditMode.rowKey && inEditMode.touched) {
            console.log(`not saved id: ${inEditMode.rowKey} value: ${anvizID}`);

            const idx = employees.findIndex(v => v.id === inEditMode.rowKey);
            let newEmployee = JSON.parse(JSON.stringify(employees[idx]));
            newEmployee.anviz_id = anvizID?? null;

            employeeActions.patch(newEmployee)
                .then(response => {
                    toggleIsLoading(false);
                    if (response.json.length) {
                        setEmployees(response.json)
                    }

                    setInEditMode({
                        status: true,
                        touched: false,
                        rowKey: id
                    })
                    setAnvizID(anviz_id);
                })
                .catch(error => {
                    // setError('apiError', { message: error });
                });
        } else {
            setInEditMode({
                status: true,
                touched: false,
                rowKey: id
            })

            setAnvizID(anviz_id);
        }
    }

    const onChange = (value) => {
        console.log(`onChange value: ${value}`);

        setAnvizID(value);

        setInEditMode({
            status: inEditMode.status,
            touched: true,
            rowKey: inEditMode.rowKey
        });
    }

    const loadButtonClassnames = classNames({
        'button is-primary': true,
        'is-loading': isLoading
    });

    return (
        <div>
            <div className='container mt-5'>
                <h1>Hi {auth?.user.login}!</h1>
                <p>You're logged in!</p>
            </div>

            <div className='container mt-5'>
                <div className='box'>
                    <button className={loadButtonClassnames} onClick={() => handleLoadEmployeesHolded()}>
                        Sync Holded
                    </button>
                </div>
                {
                    employees?
                        <table className='table is-striped is-fullwidth'>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Holded ID</th>
                                <th>Anviz ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                employees.map((item, i) => {
                                    const id = item['id'];
                                    const first_name = item['first_name'];
                                    const last_name = item['last_name'];
                                    const holded_id = item['holded_id'];
                                    const anviz_id = item['anviz_id'];

                                    return (
                                        <tr key={i}>
                                            <th>{id}</th>
                                            <td>{first_name}</td>
                                            <td>{last_name}</td>
                                            <td>{holded_id}</td>
                                            <td onClick={() => onEdit({id, anviz_id})}>
                                                {
                                                    inEditMode.status && inEditMode.rowKey === id ? (
                                                        <input value={anvizID}
                                                               onChange={(event) => onChange(event.target.value)}
                                                        />
                                                    ) : (
                                                        anviz_id
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            </tbody>
                        </table>:null
                }

            </div>
        </div>
    );
}